import { Routes } from '@angular/router';
import {UserRolesEnum} from "@revolve-app/app/core/metadata/enums/account/user-role.enum";
import {RoleGuard} from "@revolve-app/app/core/utils/guard";
import {NotAuthGuard} from "@revolve-app/app/core/utils/not-auth-guard";
export const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./theme/layouts/guest/guest-routing.module').then(m => m.GuestRoutingModule)
  },
  // {
  //   path: 'playground',
  //   loadChildren: () => import('./playground/playground-routing.module').then(m => m.PlaygroundRoutingModule)
  // },
  {
    path: 'auth',
    canActivate: [NotAuthGuard],
    loadChildren: () => import('./theme/layouts/auth/auth-routing.module').then(m => m.AppRoutingModule),
  },
  {
    path: 'customer',
    canActivate: [RoleGuard],
    loadChildren: () => import('./theme/layouts/customer/customer-routing.module').then(m => m.CustomerRoutingModule)
  },
  {
    path: 'admin',
    canActivate: [RoleGuard],
    data: {
      roles: [UserRolesEnum.CUSTOMER, UserRolesEnum.PLATFORM_ADMIN, UserRolesEnum.SUPER_ADMIN, UserRolesEnum.PLATFORM_MANAGER]
    },
    loadChildren: () => import('./theme/layouts/customer/admin-routing.module').then(m => m.AdminRoutingModule)
  },
  {
    path: '**',
    loadComponent: () => import('./theme/components/404/not-found.component').then(m => m.NotFoundComponent)
  },
];
