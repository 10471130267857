import { createAction, props } from '@ngrx/store';
export const HTTP_REQUEST_START = '[http_request] start';
export const HTTP_REQUEST_END = '[http_request] end';
export const HTTP_REQUEST_PENDING = '[http_request] pending';


export const startHttpRequest = createAction(HTTP_REQUEST_START);
export const pendingHttpRequest = createAction(HTTP_REQUEST_PENDING, props<{progress: number}>());
export const endHttpRequest = createAction(HTTP_REQUEST_END);

