import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageKeys, StorageService } from '@revolve-app/app/core/utils/storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const token = StorageService.getItem(StorageKeys.TOKEN);
    if (token) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}
