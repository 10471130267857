import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideStore } from '@ngrx/store';
import {HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {HttpInterceptorService} from "@revolve-app/app/core/services/network/http/http-interspector.service";
import {TranslateModule} from "@ngx-translate/core";
import {provideTranslation} from "@revolve-app/app/core/config/i18n";
// import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
// import { __DEV__ } from '@apollo/client/utilities/globals';
//
// if (__DEV__) {  // Adds messages only in a dev environment
//   loadDevMessages();
//   loadErrorMessages();
// }
export const appConfig: ApplicationConfig = {
  providers: [
    HttpInterceptorService,
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptors([HttpInterceptorService.interceptor])),
    provideStore(),
    importProvidersFrom(TranslateModule.forRoot(provideTranslation())),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true  // `multi: true` is required because HTTP_INTERCEPTORS is a multi-provider token
    }
  ]
};
