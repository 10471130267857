import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpResponse,
  HttpInterceptorFn
} from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import {catchError, tap, finalize, filter, takeUntil, map} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {endHttpRequest, startHttpRequest} from "@revolve-app/app/core/store/http-request/http-request.actions";
import {addNotification} from "@revolve-app/app/shared/notifier/store/notification.actions";
import {NotificationTypes} from "@revolve-app/app/shared/notifier/notifier-item/notifier-item.component";
import {HttpCodesEnum} from "@revolve-app/app/core/metadata/enums/base/http_status.enum";
import {TBaseErrorResponse, TBaseSuccessResponse} from "@revolve-app/app/core/metadata/types/base.models";
import {signOutSuccess} from "@revolve-app/app/core/features/auth/store/auth/auth.actions";
export class HttpInterceptorService {
  static requests = new Map<string, Subject<void>>();
  static store :any;

  static interceptor: HttpInterceptorFn = (req, next) => {
    const skipEndpoints = [
      '/assets/i18n/en.json',
      '/assets/i18n/hy.json',
      '/assets/i18n/ru.json',
    ];

    // Check if the request URL matches any of the skip endpoints
    const shouldSkip = skipEndpoints.some(url => req.url.includes(url));

    if (shouldSkip) {
      // If the request URL matches, bypass the interceptor
      return next(req);
    }

    const ignoreInterceptor = req?.headers?.get('ignore-interceptor') === 'true';

    if(ignoreInterceptor){console.log('ignore-interceptor', req.headers.get('ignore-interceptor'))
      return next(req);
    }
    if(!HttpInterceptorService.store){
      HttpInterceptorService.store = inject(Store)
    }
    const key = req.urlWithParams;  // Identify requests by URL and query params
    if (HttpInterceptorService.requests.has(key)) {
      // If there is an ongoing request with the same key, cancel it
      HttpInterceptorService.requests.get(key)?.next();
    }

    const stop = new Subject<void>();
    HttpInterceptorService.requests.set(key, stop);
    const showLoader = req.headers.get('Show-Loader') === 'true';
    if(showLoader){
      HttpInterceptorService.store.dispatch(startHttpRequest());
    }


    const showSuccess = req.headers.get('Show-Success') === 'true';
    return next(req).pipe(
      takeUntil(stop),
      tap(evt => {
        if (evt instanceof HttpResponse) {
          HttpInterceptorService.requests.delete(key); // Remove request from map when it completes
          const formatResponse = evt.body as TBaseErrorResponse | TBaseSuccessResponse;
          let formattedResponse;
          switch (formatResponse.code){
            case HttpCodesEnum.SUCCESS:
              formattedResponse = HttpInterceptorService.handleSuccess(formatResponse as TBaseSuccessResponse, showSuccess);
              return next(formattedResponse);
            case HttpCodesEnum.UNAUTHORIZED:

              return HttpInterceptorService.handleUnauthorized(formatResponse as TBaseErrorResponse);
            case HttpCodesEnum.INTERNAL_ERROR:
              return HttpInterceptorService.handleInternalError(formatResponse as TBaseErrorResponse);
            case HttpCodesEnum.BAD_DATA:
              return HttpInterceptorService.handleBadData(formatResponse as TBaseErrorResponse);
            default:
              throw new HttpErrorResponse({
                error: "message" in formatResponse && formatResponse.message || 'Unknown error',
                status: HttpCodesEnum.UNKNOWN,
                statusText: 'Unknown error',
              });
          }
        }
        return throwError(() => new Error('Unexpected response code'));
      }),
      catchError((error: HttpErrorResponse) => {
        HttpInterceptorService.requests.delete(key);
        HttpInterceptorService.store.dispatch(addNotification({payload: {
            title: error.error,
            description: error.statusText,
            type: NotificationTypes.error
        }}));
        if(error.status === HttpCodesEnum.UNAUTHORIZED){
          HttpInterceptorService.store.dispatch(signOutSuccess())
        }
        return throwError(() => new Error(error.message || 'Server Error'));
      }),
      finalize(() => {
        if (showLoader) {
          HttpInterceptorService.store.dispatch(endHttpRequest());
        }
      })
    );
  }

  static handleSuccess(data: TBaseSuccessResponse, showSuccess: boolean){
    if(data.data){
      return data.data;
    } else {
      if(showSuccess){
        HttpInterceptorService.store.dispatch(addNotification({payload: {
            title: 'success',
            description: 'request_successfully_done',
            type: NotificationTypes.success
          }}));
      }
      return {data: true};
    }
  }

  static handleUnauthorized(data: TBaseErrorResponse){
    throw new HttpErrorResponse({
      error: 'unauthorized',
      status: NotificationTypes.error,
      statusText: 'you_have_not_permission',
    });
  }

  static handleInternalError(data: TBaseErrorResponse){
    throw new HttpErrorResponse({
      error: 'internal_server_error',
      status: HttpCodesEnum.INTERNAL_ERROR,
      statusText: 'please_contact_support',
    });
  }

  static handleBadData(data: TBaseErrorResponse){
    if(typeof data.message === 'string'){
      throw new HttpErrorResponse({
        error: 'bad_data',
        status: HttpCodesEnum.BAD_DATA,
        statusText: data.message,
      });
    } else {
      throw new HttpErrorResponse({
        error: 'bad_data',
        status: HttpCodesEnum.BAD_DATA,
        statusText: data.message.join('<br/>'),
      });
    }

    return throwError(() => new Error('bad_data'));
  }
}
