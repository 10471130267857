import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageKeys, StorageService } from '@revolve-app/app/core/utils/storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const roles = route.data['roles'] || [];
    const userRole = StorageService.getJsonItem(StorageKeys.USEROBJECT)?.role;
    if (roles.indexOf(userRole) > -1) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
